import React from "react";

import styled from "styled-components";

//STYLES
const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  div {
    left: 50%;
    top: 50%;
    position: absolute;
    p {
      padding: 20px;
      font-family: "Futura PT";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.2px;

      color: #ffffff;
    }
  }
`;
//STYLES

const Template404 = (props) => {
  //PROPS
  const {
    dimensions,
    data: {
      page: { info404 }
    }
  } = props;
  //PROPS

  return (
    <Wrapper>
      {dimensions.width > 1023 ? (
        <img
          src={info404.image404?.localFile?.childImageSharp?.fluid?.src}
          srcSet={info404.image404?.localFile?.childImageSharp?.fluid?.srcSet}
          alt={info404.image404?.altText}
        />
      ) : (
        <img
          src={info404.mobileImage404?.localFile?.childImageSharp?.fluid?.src}
          srcSet={
            info404.mobileImage404?.localFile?.childImageSharp?.fluid?.srcSet
          }
          alt={info404.mobileImage404?.altText}
        />
      )}
      <div>
        <p>Opps! Alguma coisa está errada e não sou eu...</p>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  query info404($id: String) {
    page: wpPage(id: { eq: $id }) {
      info404 {
        image404 {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
        mobileImage404 {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;

export default Template404;
